import styled, { css } from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { colors } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

interface TextButtonStyledProps {
  $iconUrl?: string;
  $iconLeft?: boolean;
}

export const TextButtonStyled = styled.button<TextButtonStyledProps>`
  margin: 0;
  padding: ${spacing.small};
  background-color: transparent;
  border: none;
  appearance: none;
  font-family: ${fontFamilies.fontRegular};
  font-size: ${fontSizes.body};
  display: flex;
  align-items: center;

  p {
    color: ${colors.solidBlack};
  }

  :hover {
    cursor: pointer;

    p {
      color: ${colors.solidBlackHover};
    }
  }

  &:focus {
    outline: none;
  }
  ${({ $iconUrl, $iconLeft }) =>
    $iconUrl
    && !$iconLeft
    && css`
      &::after {
        content: '';
        display: none;
        display: inline-flex;
        width: ${spacing.small};
        height: ${spacing.small};
        background-image: url(${$iconUrl});
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: ${spacing.xsmall};
        transition: transform 0.1s linear;
      }
    `}

  ${({ $iconUrl, $iconLeft }) =>
    $iconUrl
    && $iconLeft
    && css`
      &::before {
        content: '';
        display: none;
        display: inline-flex;
        width: ${spacing.small};
        height: ${spacing.small};
        background-image: url(${$iconUrl});
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: ${spacing.xsmall};
        transition: transform 0.1s linear;
      }
    `}
`;
