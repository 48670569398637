import { Children, useMemo, useState } from 'react';

import Slider from 'react-slick';

import { Gauge } from '../Gauge/Gauge';
import { ProductElement } from '../product/product';

import { RelatedProductsSliderStyled } from './RelatedProductsSlider.styled';
interface RelatedProductsSliderProps {
  children?: ProductElement | Iterable<ProductElement>;
}
export const RelatedProductsSlider: React.FC<RelatedProductsSliderProps> = ({
  children,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = useMemo(() => {
    return Children.count(children) ?? 0;
  }, [children]);

  const toGo = useMemo(() => {
    const index = currentIndex ? currentIndex + 1 : 1;

    return ((length - index) / length) * 100;
  }, [currentIndex, length]);
  const soFar = useMemo(() => {
    const index = currentIndex ? currentIndex + 1 : 1;
    return (index / length) * 100;
  }, [currentIndex, length]);

  const changedIndex = (_prevIndex: number, index: number) => {
    setCurrentIndex(index);
  };

  const SETTINGS = {
    adaptiveHeight: false,
    infinite: false,
    speed: 500,
    lazyLoad: false,
    sliderPerRow: 1,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    beforeChange: changedIndex,
  };

  return (
    <RelatedProductsSliderStyled>
      <div className="sliderWrapper">
        <Slider {...SETTINGS} className="article">
          {children}
        </Slider>
      </div>
      {length > 1 && <Gauge toGo={toGo} soFar={soFar} />}
    </RelatedProductsSliderStyled>
  );
};
