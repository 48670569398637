import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const SafetyStandardTableBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.gray4};
  padding-top: ${spacing.medium};
  width: 1400px;
  max-width: calc(100vw - 40px);
  margin-inline: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: ${spacing.large};
    gap: 50px;
  }
`;

export const SafetyStandardTableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;

  h2 {
    font-style: normal;
    font-family: ${fontFamilies.fontRegularBold};
    font-weight: ${fontWeights.fontMedium};
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    margin: ${spacing.small} 0;
  }
`;

export const DescriptionsWrapper = styled.div`
  padding-block: ${spacing.xlarge};
  padding-left: 16px;
  display: grid;
  width: 100%;
  max-width: 900px;
  gap: ${spacing.xsmall};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Description = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;

  p:first-child {
    font-weight: bolder;
  }

  p:last-child {
    max-width: 340px;
  }
`;
