import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontFamilies,
  fontWeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const ContactStoresStyled = styled.div`
  padding: 0 ${spacing.small};
  margin-block: ${spacing.large};

  h2 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    margin-bottom: ${spacing.xsmall};
  }

  .AccordionWrapper:first-of-type {
    margin-bottom: ${spacing.large};
  }

  .AccordionWrapper:last-of-type {
    margin-bottom: 120px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-bottom: ${spacing.xl};
    }
  }
`;
