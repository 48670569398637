import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

export const OnlineStoreListStyled = styled.div`
  a {
    display: flex;
    padding: ${spacing.regular} 0;
    text-decoration: none;
    border-bottom: 1px solid ${colors.gray4};
    position: relative;
    font-size: ${fontSizes.body};
    line-height: ${lineHeights.body};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    color: ${colors.black};
  }

  a:last-child {
    border-bottom: none;
  }

  svg {
    position: absolute;
    right: 0;

    path {
      stroke: ${colors.hellBlue};
    }
  }

  a:hover {
    color: ${colors.blackHover};

    svg {
      path {
        stroke: ${colors.hellBlueHover};
      }
    }
  }
`;
