import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const DownloadFileBoxStyled = styled.span`
  a {
    position: relative;
    background-color: ${colors.gray5};
    width: 100%;
    min-height: var(--header-height);
    display: flex;
    flex-direction: row;
    text-decoration: none;
    padding: ${spacing.small} 0;
    margin-bottom: ${spacing.xsmall};

    :hover {
      cursor: pointer;
      background-color: ${colors.gray4};
    }
  }

  .Icon {
    position: absolute;

    &:first-of-type {
      left: ${spacing.xsmall};
      top: ${spacing.small};
    }

    &:last-of-type {
      right: ${spacing.xsmall};
      top: ${spacing.small};
    }

    height: 16px;
    width: 16px;
  }

  .TextWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    text-align: left;
  }

  p {
    &.Label {
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.bodyXS};
      margin: 0;
    }

    &.Size {
      color: ${colors.gray1};
      margin: 0;
    }
  }
`;
