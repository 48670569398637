import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/solidgear/helpers';

interface SectionStyledProps {
  $hide?: boolean;
  $marginTop?: boolean;
}
export const SectionStyled = styled.section<SectionStyledProps>`
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  margin: ${spacing.medium} ${spacing.small};

  &.BorderTop {
    padding: ${spacing.medium} ${spacing.small};
    margin: 0;
    border-top: thin solid ${colors.gray4};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} ${spacing.regular};

    &.BorderTop {
      padding: ${spacing.large} ${spacing.regular};
      margin: 0;
    }
  }

  &.FullWidth {
    padding-left: 0;
    padding-right: 0;
  }

  &.SmallMarginTop {
    margin: ${spacing.block} 0 ${spacing.medium} 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: ${spacing.large} 0 ${spacing.xlarge} 0;
    }
  }

  &.FullBleed {
    padding: 0;
  }

  &.BorderTopDesktop {
    @media screen and (min-width: ${breakpoints.desktop}) {
      border-top: 1px solid ${colors.gray4};
      padding-top: ${spacing.xlarge};
    }
  }

  &.BorderBottomDesktop.NoMargin {
    @media screen and (min-width: ${breakpoints.desktop}) {
      border-bottom: 1px solid ${colors.gray4};
    }
  }

  &.MoreMarginBottom {
    padding-bottom: ${spacing.large};

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-bottom: ${spacing.xl};
    }
  }

  &.NoMarginTop {
    margin-top: 0;
    padding-top: 0;
  }

  &.NoMargin {
    margin: 0;
  }

  margin-top: ${({ $marginTop }) =>
    $marginTop ? spacing.large : null} !important;
`;
