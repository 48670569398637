import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/solidgear/helpers';

const menuHorizontalPadding = spacing.regular;

export const HeroGeneralContentWrapperStyled = styled.div`
  padding: ${spacing.medium} ${menuHorizontalPadding};

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    padding: ${spacing.medium} 0;
  }

  max-width: ${breakpoints.maxPageWidth};
  display: flex;
`;

interface HeroGeneralStyledProps {
  $height?: CSSProperties['height'];
  $hasStoryVideo?: boolean;
}
export const HeroGeneralStyled = styled.div<HeroGeneralStyledProps>`
  justify-content: center;
  aspect-ratio: ${({ $hasStoryVideo }) =>
    !$hasStoryVideo ? '1 / 1' : '9 / 16'};
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  background-color: ${colors.gray4};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 12 / 5;
  }

  .HeroVideoContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
`;

export const Image = styled.div`
  position: absolute;
  inset: 0;
`;

export const HeroVideoStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeroVideoInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 60%);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const VideoBig = styled.video<{ $hideOnMobile?: boolean }>`
  display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'block')};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

export const VideoSmall = styled.video`
  display: block;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
