import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

export const ContentPlugStyled = styled(RouterLink)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background: ${colors.white};
  aspect-ratio: 1 / 1;

  a,
  .link {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin-bottom: ${spacing.medium};
    width: 100%;

    &:hover {
      .Image {
        opacity: 0.95;
      }
    }
  }

  .Grid {
    width: 100%;
    padding-bottom: ${spacing.regular};
    position: relative;
  }

  .TextBoxWrapper {
    width: 100%;
    text-align: center;
    padding: 0 ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0;
    }
  }

  .TextBox {
    appearance: none;
    max-width: 350px;
    min-width: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.small} ${spacing.medium};
    box-sizing: border-box;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    font-weight: ${fontWeights.fontMedium};
    background-color: ${colors.gray5};
    color: ${colors.solidBlack};
  }
`;
