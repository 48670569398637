import { createDetailPageUrl } from '@hultafors/solidgear/helpers';
import { useFavorites, useGlobal } from '@hultafors/solidgear/hooks';
import { SolidgearProduct } from '@hultafors/solidgear/types';

import { Badge } from '../Badge/Badge';
import { Paragraph } from '../paragraph/paragraph';
import { ProductImage } from '../ProductImage/ProductImage';
import { SafetyClassIcon } from '../SafetyClassIcon/SafetyClassIcon';

import { Outer, ProductStyled, StyledFavorite } from './product.styled';

interface ProductProps {
  product: SolidgearProduct;
  toggleSearchBox?: (type: string) => void;
  rrpLabel?: string | null;
  rrpIncludingVatLabel?: string | null;
  isSmall?: boolean;
  inFavorites?: boolean;
  noHover?: boolean;
  className?: string;
}
export type ProductElement = React.ReactElement<ProductProps>;
export const Product: React.FC<ProductProps> = ({
  product,
  toggleSearchBox,
  rrpLabel,
  rrpIncludingVatLabel,
  className,
  isSmall = false,
  inFavorites = false,
  noHover = false,
}) => {
  const { settings } = useGlobal();
  const { toggleFavoriteItem, isProductFavorite } = useFavorites();

  const handleClick = () => {
    toggleSearchBox?.('search');
  };
  if (!product) {
    return null;
  }
  const href = createDetailPageUrl({
    name: product.name,
    parentCategory: product.parentCategory || '',
    id: product.id,
    sku: product.sku,
    settings,
  });
  return (
    <Outer>
      <StyledFavorite
        toggleFavorite={toggleFavoriteItem}
        product={product}
        isFavorite={isProductFavorite(product.sku)}
        inFavorites={inFavorites}
      />

      <ProductStyled
        $noHover={noHover}
        $inFavorites={inFavorites}
        $isSmall={isSmall}
        className={className}
        href={href}
        onClick={handleClick}
      >
        <div className={`productWrapper ${product.id}`}>
          <div className="ProductImage">
            <ProductImage product={product} />

            {product.productFeature && (
              <Badge styleName="TopLeft" new>
                {product.productFeature}
              </Badge>
            )}
            {product.safetyClass && (
              <span className="safetyClass">
                <SafetyClassIcon
                  safetyClass={product.safetyClass}
                  filled={false}
                />
              </span>
            )}
          </div>

          <div className="ProductInfo">
            {product.name}
            {/* </RouterLink> */}
            {product?.price?.value
              ? (
                <div className="ProductPrice">
                  <Paragraph>{`${product.price.currency} ${product.price.value}`}</Paragraph>
                  <span className="rrp">
                    {rrpLabel}
                    {' '}
                  </span>
                </div>
                )
              : product?.priceWithVat?.value
                ? (
                  <div className="ProductPrice">
                    <Paragraph>{`${product.priceWithVat.currency} ${product.priceWithVat.value}`}</Paragraph>
                    <span className="rrp">
                      {rrpIncludingVatLabel}
                      {' '}
                    </span>
                  </div>
                  )
                : null}

            {product.usp && product.usp.length > 0 && (
              <div className="USP">
                <ul>
                  {product.usp.map((item, index) => (
                    <li key={`usp-l-${index}`}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </ProductStyled>
    </Outer>
  );
};
