import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';

export const STATUSES = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const;
export type AlertStatus = keyof typeof STATUSES;

export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;
export type AlertSize = keyof typeof SIZES;

export type AlertPlacement = 'bottom' | 'top';

interface StyledAlertProps {
  $status?: AlertStatus;
  $size?: AlertSize;
  $position?: CSSProperties['position'];
  $placement?: AlertPlacement;
}

const getStatusStyle = ({ $status }: StyledAlertProps) => {
  if (!$status) {
    return;
  }
  return {
    info: css`
      background: ${colors.white};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
    warning: css`
      background: ${colors.warning};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
    error: css`
      background: ${colors.warning};
      color: ${colors.white};

      button {
        background: ${colors.white};
        color: ${colors.black};
      }
    `,
    success: css`
      background: ${colors.ok};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
  }[$status in STATUSES ? $status : 'info'];
};

const getSizeStyle = ({ $size }: StyledAlertProps) => {
  if (!$size) {
    return;
  }
  return {
    small: css({
      padding: spacing.xsmall,
    }),
    medium: css({
      padding: spacing.small,
    }),
    large: css({
      padding: spacing.medium,
    }),
  }[$size in SIZES ? $size : 'medium'];
};

const getPositionStyle = ({ $position, $placement }: StyledAlertProps) => {
  if ($position === 'absolute') {
    return css`
      inset: ${$placement === 'top' ? 0 : 'auto'} 0
        ${$placement === 'bottom' ? 0 : 'auto'} 0;
      width: 100%;
      margin: 0 auto;
    `;
  }
  if ($position === 'fixed') {
    return css`
      inset: ${$placement === 'top' ? 0 : 'auto'} 0
        ${$placement === 'bottom' ? 0 : 'auto'} 0;
      width: 100%;
      margin: 0 auto;
    `;
  }
  return;
};
export const StyledAlert = styled.div<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: ${fontSizes.bodyS};
  line-height: ${lineHeights.bodyS};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    align-items: center;
  }

  button {
    appearance: none;
    padding: ${spacing.xsmall} ${spacing.small};
    border: none;
    font-size: ${fontSizes.bodyS};
    line-height: ${lineHeights.bodyS};
    min-height: 0;

    &:hover {
      background-color: ${colors.solidBlackHover};
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      &:last-child {
        margin: 0 0 0 0.25em;
      }
    }
  }

  margin: ${spacing.small} auto;
  ${getPositionStyle};
  ${getStatusStyle};
  ${getSizeStyle};
`;
