import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

export const FindRetailersHeroStyled = styled.div`
  margin: 0;
  margin-top: ${spacing.mobileTop};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-top: ${spacing.large};
  }

  h1 {
    letter-spacing: 1px;
    font-family: ${fontFamilies.fontHero};
    font-style: normal;
    font-weight: normal;
    font-size: ${fontSizes.header3};
    line-height: ${lineHeights.header3};
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }

  .HeroDescription {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    text-align: center;
  }
`;
