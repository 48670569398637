import Link from 'next/link';
import styled, { css } from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';

interface TextLinkStyledProps {
  $iconUrl?: string;
}

export const TextLinkStyled = styled(Link)<TextLinkStyledProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  ${({ $iconUrl }) =>
    $iconUrl
    && css`
      &::after {
        content: '';
        display: none;
        display: inline-flex;
        width: ${spacing.small};
        height: ${spacing.small};
        background-image: ${$iconUrl};
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: ${spacing.xsmall};
        transition: transform 0.1s linear;
      }
    `}
`;
