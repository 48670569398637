import { useEffect, useState } from 'react';

import { useWindowSize } from '@react-hookz/web';
import dynamic from 'next/dynamic';

import { breakpointsRaw } from '@hultafors/shared/constants';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { HeroBlockFragment, ImageFragment } from '@hultafors/solidgear/types';

import {
  Description,
  HeroBlockStyled,
  HeroButtonWrapper,
  HeroContent,
  HeroInner,
  HeroVideo,
  InnerButtonWrapper,
  InnerContent,
  Title,
} from './HeroBlock.styled';

const ButtonLink = dynamic(() =>
  import('../ButtonLink/ButtonLink').then((mod) => mod.ButtonLink),
);
const HeroImage = dynamic(() =>
  import('../HeroImage/HeroImage').then((mod) => mod.HeroImage),
);

const Paragraph = dynamic(() =>
  import('../paragraph/paragraph').then((mod) => mod.Paragraph),
);

type HeroBlockImageProps = Pick<
  HeroBlockFragment,
  'imageDesktop' | 'imageMobile' | 'imageTablet'
>;

type HeroBlockImage = HeroBlockImageProps & ImageFragment;

interface HeroBlockProps extends HeroBlockFragment {
  priority?: boolean;
  isInSlider?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  title,
  topLabel,
  firstCtaLabel,
  secondCtaLabel,
  firstCtaUrl,
  secondCtaUrl,
  imageDesktop,
  imageTablet,
  imageMobile,
  oldMobileImage,
  largePlugImageDesktop,
  largePlugImageTablet,
  largePlugImageMobile,
  largePlug,
  fullWidth,
  priority,
  storyVideo,
  video,
  description,
  alignContentLeft,
  useOldMobileImage,
  isInSlider,
}) => {
  const { settings } = useGlobal();
  const [playbackId, setPlaybackId] = useState<string | undefined>(
    storyVideo?.video?.muxPlaybackId ?? undefined,
  );
  const [placeholder, setPlaceholder] = useState<string | undefined>(
    storyVideo?.blurUpThumb ?? undefined,
  );

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
      setPlaceholder(video?.blurUpThumb ?? undefined);
    } else {
      setPlaybackId(storyVideo?.video?.muxPlaybackId ?? '');
      setPlaceholder(storyVideo?.blurUpThumb ?? undefined);
    }
  }, [width]);

  const mobileImage = !largePlug ? imageMobile : largePlugImageMobile;
  const tabletImage = !largePlug
    ? imageTablet
    : (largePlugImageTablet as HeroBlockImage);

  const desktopImage = !largePlug
    ? imageDesktop
    : (largePlugImageDesktop as HeroBlockImage);

  let typeOfHeading: 'h1' | 'h2' = 'h2';

  if (priority) {
    typeOfHeading = 'h1';
  }

  // For making hero look like old campaign hero when when options checked
  const shouldBeTwelveFive = alignContentLeft && largePlug;

  return (
    <HeroBlockStyled
      data-hero
      $mobileImage={mobileImage}
      $tabletImage={shouldBeTwelveFive ? desktopImage : tabletImage}
      $desktopImage={desktopImage}
      $isLargePlug={largePlug}
      $fullWidth={fullWidth}
      $storyVideo={storyVideo}
      $twelveFiveAspectRatio={shouldBeTwelveFive}
      $video={video}
    >
      {!playbackId && (
        <HeroImage
          mobile={useOldMobileImage ? oldMobileImage : mobileImage}
          tablet={tabletImage}
          desktop={desktopImage}
          priority={priority}
        />
      )}

      {playbackId && (
        <HeroVideo
          playbackId={playbackId}
          placeholder={placeholder}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            inset: 0,
          }}
        />
      )}

      <HeroContent $isLargePlug={largePlug} $isInSlider={isInSlider}>
        <HeroInner
          $isLargePlug={largePlug}
          $alignContentLeft={alignContentLeft}
        >
          <InnerContent
            $isLargePlug={largePlug}
            $alignContentLeft={alignContentLeft}
          >
            {topLabel && (
              <Paragraph color="white" bold={true} hasLeftBorder={true}>
                {topLabel}
              </Paragraph>
            )}
            <Title type={typeOfHeading} $isLargePlug={largePlug}>
              {title}
            </Title>
            {description && (
              <Description options={{ forceBlock: true }}>
                {description}
              </Description>
            )}
            {(firstCtaLabel || secondCtaLabel) && (
              <HeroButtonWrapper
                $image={!!imageDesktop || !!imageTablet || !!imageMobile}
                $isLargePlug={largePlug}
              >
                <InnerButtonWrapper>
                  {firstCtaLabel && firstCtaUrl && (
                    <ButtonLink
                      disabled={false}
                      href={createUrl(firstCtaUrl, settings) || ''}
                      className="White"
                    >
                      {firstCtaLabel}
                    </ButtonLink>
                  )}
                  {secondCtaLabel && secondCtaUrl && (
                    <ButtonLink
                      disabled={false}
                      href={createUrl(secondCtaUrl, settings) || ''}
                      className="White"
                    >
                      {secondCtaLabel}
                    </ButtonLink>
                  )}
                </InnerButtonWrapper>
              </HeroButtonWrapper>
            )}
          </InnerContent>
        </HeroInner>
      </HeroContent>
    </HeroBlockStyled>
  );
};
