import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/solidgear/helpers';

export const FooterStyled = styled.div`
  width: 100%;
  margin-top: ${spacing.medium};
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: ${fontSizes.small};

  a,
  .link {
    cursor: pointer;
    text-decoration: none;

    &.Underline {
      text-decoration: underline;
    }
  }

  span,
  a,
  .link,
  p,
  span a,
  span .link {
    color: ${colors.white};
    font-size: ${fontSizes.small};
    letter-spacing: 0;
  }

  .Grid {
    position: relative;
  }

  section {
    padding-inline: ${spacing.pagePaddingMobile};
    padding-block: 32px ${spacing.small};
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-inline: ${spacing.pagePadding};
      padding-block: ${spacing.medium} 10px;
    }
  }

  .LogoWrapper {
    margin-bottom: ${spacing.regular};
    display: block;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-bottom: ${spacing.medium};
    }
  }

  .SocialMedia a,
  .SocialMedia .link {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  .Underline {
    a,
    .link {
      text-decoration: underline;
    }
  }

  a:hover,
  .link:hover {
    color: ${colors.gray1};
  }

  .SocialMedia a:not(:last-of-type),
  .SocialMedia .link:not(:last-of-type) {
    margin-right: ${spacing.regular};
  }

  .SocialMedia {
    display: flex;
    margin-top: ${spacing.small};

    @media screen and (min-width: ${breakpoints.desktop}) {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  .Dropdown {
    display: flex;
    align-items: center;
    margin-right: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-right: 32px;
    }
  }

  .FooterFunctions {
    display: flex;
    margin-bottom: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.desktop}) {
      position: absolute;
      top: 2px;
      display: flex;
      right: 0;
      margin: 0;
    }

    .Dropdown {
      margin-left: -10px;
    }

    .PartnerLoginLink {
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;

      img {
        width: ${spacing.small};
        height: ${spacing.small};
        margin-left: ${spacing.xsmall};
      }
    }
  }
`;

export const CookieSettings = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  color: ${colors.white};
  font-size: ${fontSizes.small};
  letter-spacing: 0;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${colors.gray1};
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex-basis: 65%;
  text-align: left;
  margin-bottom: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }

  p,
  a,
  .link {
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 600px;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
