import MuxPlayer from '@mux/mux-player-react';
import Markdown from 'markdown-to-jsx';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';
import { ImageFragment, MuxVideoFragment } from '@hultafors/solidgear/types';

import { HTag } from '../HTag/HTag';

interface HeroBlockStyledProps {
  $isLargePlug?: boolean;
  $fullWidth?: boolean;
  $storyVideo?: MuxVideoFragment | null;
  $video?: MuxVideoFragment | null;
  $mobileImage?: ImageFragment | null;
  $tabletImage?: ImageFragment | null;
  $desktopImage?: ImageFragment | null;
  $twelveFiveAspectRatio?: boolean;
}

export const HeroBlockStyled = styled.div<HeroBlockStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: ${colors.gray2};
  background-repeat: no-repeat;
  background-size: cover;
  margin-inline: auto;
  overflow: hidden;
  color: ${colors.solidBlack};
  ${({ $mobileImage, $tabletImage, $desktopImage }) =>
    ($mobileImage || $tabletImage || $desktopImage)
    && css`
      color: ${colors.white};
    `}
  ${({ $mobileImage }) =>
    $mobileImage
    && css`
      background-color: ${$mobileImage.responsiveImage?.bgColor};
    `}
      ${({ $mobileImage, $storyVideo }) =>
    $mobileImage
    && !$storyVideo
    && css`
      background-image: url(${$mobileImage.responsiveImage?.base64});
    `}
    ${({ $tabletImage }) =>
    $tabletImage
    && css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        background-color: ${$tabletImage.responsiveImage?.bgColor};
      }
    `}
  ${({ $tabletImage, $video }) =>
    $tabletImage
    && !$video
    && css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        background-image: url(${$tabletImage.responsiveImage?.base64});
      }
    `}
  ${({ $desktopImage }) =>
    $desktopImage
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        background-color: ${$desktopImage.responsiveImage?.bgColor};
      }
    `}
  ${({ $desktopImage, $video }) =>
    $desktopImage
    && !$video
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        background-image: url(${$desktopImage.responsiveImage?.base64});
      }
    `}
  ${({ $storyVideo }) =>
    $storyVideo?.blurUpThumb
    && css`
      background-image: url(${$storyVideo.blurUpThumb});
    `}
  ${({ $video }) =>
    $video?.blurUpThumb
    && css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        background-image: url(${$video.blurUpThumb});
      }
    `}

  h1,
  h2,
  h3 {
    text-shadow: 0 0 0.5rem rgb(0 0 0 / 80%);
  }

  p,
  h4,
  h5 {
    text-shadow: 0 0 0.25rem rgb(0 0 0 / 80%);
  }

  ${({ $fullWidth }) =>
    !$fullWidth
    && css`
      max-width: 1440px;
    `}

  ${({ $storyVideo }) =>
    $storyVideo
    && css`
      aspect-ratio: 9 / 16;
    `}

    ${({ $isLargePlug }) =>
    !$isLargePlug
    && css`
      aspect-ratio: 3 / 4;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 4 / 3;
    ${({ $video }) =>
      $video
      && css`
        aspect-ratio: 16 / 9;
      `}

    ${({ $twelveFiveAspectRatio }) =>
      $twelveFiveAspectRatio
      && css`
        aspect-ratio: 12 / 5;
      `}
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: ${({ $isLargePlug, $video }) =>
      !$isLargePlug || $video ? '16 / 9' : '12 / 5'};
  }
`;

export const HeroContent = styled.div<{ $isLargePlug?: boolean; $isInSlider?: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  height: 100%;

  ${({ $isLargePlug }) =>
    !$isLargePlug
    && css`
      background-image: linear-gradient(
        114deg,
        rgb(0 0 0 / 71%) 6%,
        rgb(0 0 0 / 5%) 95%
      );
    `}

  ${({ $isLargePlug }) =>
    $isLargePlug
    && css`
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
  }

  ${({ $isInSlider }) => $isInSlider && css`
  transform: translateZ(0);
  `}
`;

export const HeroInner = styled.div<{
  $isLargePlug?: boolean;
  $alignContentLeft?: boolean;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  flex-direction: column;
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.regular};
  max-width: 501.34px;
  gap: 40px;
  align-items: center;
  grid-column: ${({ $alignContentLeft }) =>
    $alignContentLeft ? '2 / span 4' : 'span 4'};
  justify-self: stretch;

  ${({ $isLargePlug }) =>
    $isLargePlug
    && css`
      padding-block-end: ${spacing.medium};
      justify-content: flex-end;
    `}

  ${({ $alignContentLeft }) =>
    $alignContentLeft
    && css`
      p,
      h1,
      h2 {
        text-align: left;
      }

      align-items: flex-start;
    `}



  @media screen and (min-width: ${breakpoints.tablet}) {
    gap: 20px;
    grid-column: ${({ $isLargePlug }) =>
      !$isLargePlug ? '5 / span 4' : '4 / span 6'};
    grid-template-columns: repeat(12, minmax(0, 1fr));
    max-width: 873.73px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 5 / span 4;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    max-width: 1231.22px;
  }
`;

export const InnerContent = styled.div<{
  $alignContentLeft?: boolean;
  $isLargePlug?: boolean;
}>`
  grid-column: span 4;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $alignContentLeft }) =>
    $alignContentLeft
    && css`
      align-self: flex-end;
      align-items: flex-start;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 4 / span 6;
    ${({ $alignContentLeft }) =>
      $alignContentLeft
      && css`
        grid-column: 2 / span 7;
      `};
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    grid-column: 4 / span 6;
    ${({ $alignContentLeft }) =>
      $alignContentLeft
      && css`
        grid-column: 1 / span 7;
      `};
  }
`;

export const HeroButtonWrapper = styled.div<{
  $image?: boolean;
  $isLargePlug?: boolean;
}>`
  a {
    overflow: hidden;
    white-space: nowrap;
  }

  display: block;
  width: fit-content;
  align-items: center;
  text-align: center;

  ${({ $isLargePlug }) =>
    $isLargePlug
    && css`
      margin-block-start: ${spacing.small};
    `}

  ${({ $image }) =>
    !$image
    && css`
      a {
        border: 1px solid ${colors.black};
        color: ${colors.black};

        &:hover {
          background-color: ${colors.black};
          color: ${colors.white};
        }
      }
    `}
`;

export const InnerButtonWrapper = styled.span`
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-width: 100%;
`;

export const Title = styled(HTag)<{ $isLargePlug?: boolean }>`
    margin: 0;
    padding: 0;
    margin-block-end: ${spacing.regular};
    font-family: ${fontFamilies.fontHero};
    font-style: normal;
    font-weight: normal;
    font-size: ${fontSizes.header3};
    line-height: ${lineHeights.header2};
    color: ${colors.white};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 4px 30px rgb(0 0 0 / 25%);

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      font-size: ${fontSizes.header2};
      line-height: ${lineHeights.header2};
    }

     @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1};
      line-height: ${lineHeights.header1};
    }

`;

export const HeroVideo = styled(MuxPlayer).attrs({
  streamType: 'on-demand',
  loop: true,
  muted: true,
  autoPlay: 'muted',
})`
  position: absolute;
  inset: 0;

  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;
`;

export const Description = styled(Markdown)`
  font-size: ${fontSizes.h5};
  text-align: center;
  color: white;

  p {
    color: white;
  }

  a {
    color: white;
  }
`;
