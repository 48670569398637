import { createGlobalStyle, css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights } from '@hultafors/solidgear/helpers';

interface GlobalStyleProps {
  isLanguageSelectorVisible: boolean;
}

const cssVariables = css`
  :root {
    --header-height: 64px;
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
    --text-decoration-link: none;
    --text-decoration-link-hover: underline;
    --color-solid-orange: ${colors.solidOrange};
    --color-solid-orange-hover: ${colors.solidOrangeHover};
    --color-ok: ${colors.ok};
    --color-warning: ${colors.warning};
    --color-active: ${colors.active};
    --color-s1: ${colors.s1};
    --color-s1p: ${colors.s1p};
    --color-s2p: ${colors.s2p};
    --color-s3: ${colors.s3};
    --color-o1: ${colors.o1};
    --color-o2: ${colors.o2};
    --color-gray6: ${colors.gray6};
    --color-gray5: ${colors.gray5};
    --color-gray4: ${colors.gray4};
    --color-gray3: ${colors.gray3};
    --color-gray2: ${colors.gray2};
    --color-gray1: ${colors.gray1};
    --color-solid-black: ${colors.solidBlack};
    --color-solid-black-hover: ${colors.solidBlackHover};
    --color-box-shadow-transparent: ${colors.boxshadowTransparent};
    --color-black: ${colors.black};
    --color-white: ${colors.white};
    --color-overlay-transparent: ${colors.overlayTransparent};
    --color-border: ${colors.border};
    --color-text: ${colors.solidBlack};
    --color-dimmed: ${colors.gray5};
    --color-link: ${colors.solidBlack};
    --color-link-hover: ${colors.solidBlackHover};
    --min-tap-size: 48px;
    --min-tap-space: 8px;
  }
`;

const globalStyle = css<GlobalStyleProps>`
  html {
    font-size: 16px;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    font-weight: ${fontWeights.fontRegular};
    padding-top: var(--header-height);

    @media (min-width: ${breakpoints.desktop}) {
      padding-top: var(--header-height);
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.solidBlack};
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
`;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${cssVariables};
  ${globalStyle};

`;
