import styled, { css } from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';

import RemoveFavoriteSvg from '../../svg/remove-favorite.svg';
import StarSvg from '../../svg/star.svg';
export const FavoriteStyled = styled.div`
  padding: 8px;
`;

export const StyledStarSvg = styled(StarSvg)``;

export const StyledRemoveFavoriteSvg = styled(RemoveFavoriteSvg)``;

export const Inner = styled.button<{ $active?: boolean }>`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  pointer-events: all;

  ${StyledStarSvg} path {
    stroke: ${colors.black};
    fill: transparent;
    transition: all 0.2s;
  }

  ${StyledRemoveFavoriteSvg} circle {
    width: 16px;
    height: 16px;
    transition: all 0.2s;
  }

  &:hover {
    svg path {
      stroke: ${colors.gray1};
    }

    ${StyledRemoveFavoriteSvg} circle {
      stroke: ${colors.gray1};
      transition: all 0.2s;
    }
  }

  ${({ $active }) =>
    $active
    && css`
      ${StyledStarSvg} path {
        stroke: ${colors.solidBlackHover};
        fill: ${colors.solidBlackHover};
      }
    `}
`;
