import dynamic from 'next/dynamic';

import { Store } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/solidgear/hooks';
import { FindRetailersPageFragment } from '@hultafors/solidgear/types';

import { ContentListWrapper } from '../contact-list-wrapper/contact-list-wrapper';
import { StoreItem } from '../store-item/store-item';

import {
  StoreLabel,
  StoreSubTitle,
  StoreTitle,
} from './find-retailers-list.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItem),
);

interface FindRetailersListProps {
  stores: Store[];
  pageContent: FindRetailersPageFragment;
  selectItem?(...props: any[]): void;
  selectedItem?: Store;
  thisStoreAlsoSells?: string | null;
  viewOnMap?: string | null;
  visitWebsite?: string | null;
}

export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  selectItem,
  selectedItem,
  stores,
  visitWebsite = '',
  viewOnMap = '',
  thisStoreAlsoSells = '',
}) => {
  const { settings } = useGlobal();

  const keyPrefix = 'Store';
  const storeMapper = (item: Store) => {
    return (
      <AccordionItem
        key={`Store-${item.id}`}
        label={(
          <StoreLabel>
            <StoreTitle>{item.data.companyName}</StoreTitle>
            <StoreSubTitle>{item.data.city}</StoreSubTitle>
          </StoreLabel>
        )}
        plus
      >
        <StoreItem
          coordinates={{
            lng: parseFloat(
              item.data.longitude
              || `${settings.defaultCoords?.longitude}`
              || '',
            ),
            lat: parseFloat(
              item.data.latitude || `${settings.defaultCoords?.latitude}` || '',
            ),
          }}
          label={item.data.companyName || ''}
          subLabel={item.data.city || ''}
          key={`${keyPrefix}-${item.id}`}
          keyPrefix={keyPrefix}
          id={item.id}
          address={item.data.address1 || ''}
          zipCode={item.data.zipCode || ''}
          city={item.data.city || ''}
          country={item.data.country || ''}
          mapLink={item.mapLink}
          viewOnMap={viewOnMap || ''}
          emailAddress={item.data.emailAddress || ''}
          phone={item.data.phone || ''}
          storeSells={item.storeSells}
          thisStoreAlsoSells={thisStoreAlsoSells || ''}
          website={item.data.webSite || ''}
          visitWebSite={visitWebsite || ''}
        />
      </AccordionItem>
    );
  };
  return (
    <ContentListWrapper>
      {stores.length > 0 && (
        <Accordion allowZeroExpanded>{stores.map(storeMapper)}</Accordion>
      )}
    </ContentListWrapper>
  );
};
