import styled from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

export const FilterStyled = styled.div`
  position: relative;
  width: 100%;
  padding-inline: 20px;

  ul {
    list-style-type: none;
  }

  /* input {
    position: absolute;
    opacity: 0;
  } */

  .Accordion {
    > div {
      border-top: 1px solid #eee;
      padding: ${spacing.small} 0;

      h3,
      .CollapseContent {
        padding-left: ${spacing.regular};

        ::after {
          right: ${spacing.regular};
        }
      }
    }
  }

  input:checked + .CheckboxLabel::before {
    background-color: ${colors.solidBlack};
    border-color: ${colors.solidBlack};
    background-image: url('/assets/gfx/check-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  }
`;

export const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.small};
`;

export const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;

  &:hover {
    color: ${colors.solidBlackHover};
    cursor: pointer;

    &::before {
      background-color: ${colors.gray4};
    }
  }

  &:last-of-type {
    margin-bottom: 8px;
  }

  /* &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 8px;
    width: 16px;
    height: 16px;
    background-color: ${colors.gray5};
    border: 1px solid ${colors.gray3};
    border-radius: 2px;
  } */

  span {
    padding: 7px 28px 7px 26px;
    font-size: 14px;
    line-height: 18px;
    height: auto;
    border-top: none;
  }

  .ImageWrapper {
    position: absolute;
    top: 6px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    overflow: hidden;

    &.Color {
      border-radius: 50%;
      border: 1px solid ${colors.gray4};
    }

    img {
      width: 100%;
    }
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  background-color: ${colors.gray5};
  border: thin solid ${colors.gray3};
  border-radius: 2px;
  accent-color: ${colors.solidBlack};
`;
