import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const InputStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  height: 3rem;

  label {
    font-size: ${fontSizes.bodyXS};
    line-height: 20px;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    height: 3rem;
    border-radius: 2px;
    border: 0.5px solid ${colors.solidBlack};
    background-color: ${colors.white};
    line-height: 3rem;
  }

  textarea {
    border-radius: 5px;
    border: 0.5px solid ${colors.solidBlack};
    min-height: 150px;
    padding: ${spacing.small} ${spacing.medium};
    line-height: ${lineHeights.s};
    box-sizing: border-box;
  }

  input,
  textarea {
    width: 100%;
    padding: ${spacing.xsmall};
    background-color: ${colors.white};
    font-size: 14px;
    box-sizing: border-box;
    appearance: none;
    border: 0.5px solid ${colors.solidBlack};
    border-radius: 2px;

    &:focus {
      outline: 0;
    }
  }

  input:placeholder-shown {
    border-color: ${colors.solidBlack};
    color: ${colors.gray2};
  }

  &.Error {
    input {
      border-color: ${colors.warning};
    }
  }

  .ErrorMsg {
    width: 100%;
    color: ${colors.warning};
    font-size: ${fontSizes.supersmall};
  }
`;
