import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/solidgear/helpers';

export const StyledFieldWrapper = styled.div<{ $inline?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${spacing.small};
  font-size: 16px;
  height: 100%;
  padding: 4px;

  label {
    margin-bottom: 0.5em;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $inline }) =>
      $inline
        ? css`
            display: flex;
            align-items: center;
            margin: 0 -0.5em ${spacing.small};

            * {
              margin: 0 0.5em;
            }

            label {
              margin-bottom: 0;
            }
          `
        : null};
  }
`;
