import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, createProductImageSrc } from '@hultafors/solidgear/helpers';
import { SolidgearProduct } from '@hultafors/solidgear/types';

import { ImageWrapper, ProductImageStyled } from './ProductImage.styled';

interface ProductImageProps {
  product?: SolidgearProduct;
}

export const ProductImage: React.FC<ProductImageProps> = ({ product }) => {
  if (!product) {
    return null;
  }

  const caption = product?.image?.caption || '';

  const sizes = [
    '100vw',
    `(min-width: ${breakpoints.desktop}) 470px`,
    `(min-width: ${breakpoints.tablet}) 30vw`,
    `(min-width: ${breakpoints.mobileMax}) 50vw`,
  ].join(', ');
  return (
    <ProductImageStyled>
      <ImageWrapper>
        {product?.image?.url
          ? (
            <Image
              src={createProductImageSrc(product.image.url)}
              alt={caption ?? product.name}
              fill
              style={{ objectFit: 'contain', backgroundColor: colors.gray6 }}
              sizes={sizes}
            />
            )
          : (
            <Image
              src="/assets/gfx/noimage.png"
              fill
              style={{ objectFit: 'contain' }}
              alt={caption || 'no image'}
            />
            )}
      </ImageWrapper>
    </ProductImageStyled>
  );
};
