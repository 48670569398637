import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const HeroStyled = styled.div<{ $image?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 4;
  color: ${({ $image }) => ($image ? colors.white : colors.solidBlack)};
  background-color: ${colors.solidBlack};

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 4 / 3;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 16 / 9;
  }

  .HeroContent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%;
    padding-bottom: 20px;
    background-image: linear-gradient(
      114deg,
      rgb(0 0 0 / 71%) 6%,
      rgb(0 0 0 / 5%) 95%
    );
  }

  h1 {
    font-size: ${fontSizes.header2};
    line-height: ${lineHeights.header2};
    color: ${colors.white};
    font-family: ${fontFamilies.fontHero};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 4px 30px rgb(0 0 0 / 25%);

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1};
      line-height: ${lineHeights.header1};
    }
  }

  .HeroButtonWrapper a,
  .HeroButtonWrapper .link {
    overflow: hidden;
    white-space: nowrap;
  }

  .HeroButtonWrapper a:first-child {
    margin-bottom: ${spacing.small};
  }

  .HeroButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${spacing.medium};
    text-align: center;
    ${({ $image }) =>
      !$image
      && css`
        a {
          border: 1px solid ${colors.black};
          color: ${colors.black};

          &:hover {
            background-color: ${colors.black};
            color: ${colors.white};
          }
        }
      `}

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-top: ${spacing.medium};
    }
  }

  .InnerButtonWrapper {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    max-width: 100%;
  }
`;
