import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/solidgear/helpers';

export type GridColumnGapProp =
  | Spacing
  | number
  | { breakpoint?: Breakpoint; columnGap: number }[];

export type GridRowGapProp =
  | Spacing
  | number
  | { breakpoint?: Breakpoint; rowGap: number }[];

export type GridColumnsProp =
  | GridResponsiveColumn[]
  | CSSProperties['gridTemplateColumns'];

interface GridResponsiveColumn {
  breakpoint?: Breakpoint;
  columns: CSSProperties['gridTemplateColumns'];
}

interface StyledGridProps {
  $autoFlow?: CSSProperties['gridAutoFlow'];
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $columnGap?: GridColumnGapProp;
  $rowGap?: GridRowGapProp;
  $columns?: GridColumnsProp;
  $minColumnWidth?: string;
}

function getRowGap({ $rowGap }: StyledGridProps) {
  if (!$rowGap) {
    return;
  }
  if (Array.isArray($rowGap)) {
    return $rowGap.map(({ breakpoint, rowGap }) => {
      if (breakpoint) {
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            row-gap: ${rowGap}px;
          }
        `;
      }
      return css`
        row-gap: ${rowGap}px;
      `;
    });
  }

  if (typeof $rowGap === 'number') {
    return css`
      row-gap: ${$rowGap}px;
    `;
  }

  return css`
    row-gap: ${spacing[$rowGap]};
  `;
}

function getColumns({ $columns, $minColumnWidth }: StyledGridProps) {
  if (!$columns) {
    return;
  }
  if (Array.isArray($columns))
    return $columns.map(({ breakpoint, columns }) => {
      if (breakpoint) {
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            grid-template-columns: repeat(${columns}, minmax(0, 1fr));
          }
        `;
      }
      return css`
        grid-template-columns: repeat(${columns}, minmax(0, 1fr));
      `;
    });
  const columnsValue = $minColumnWidth
    ? `minmax(${$minColumnWidth}, 1fr)`
    : 'minmax(0, 1fr)';
  return css`
    grid-template-columns: repeat(${$columns}, ${columnsValue});
  `;
}
function getColumnGap({ $columnGap }: StyledGridProps) {
  if (!$columnGap) {
    return;
  }
  if (Array.isArray($columnGap)) {
    return $columnGap.map(({ breakpoint, columnGap }) => {
      if (breakpoint) {
        const columnGapValue
          = typeof columnGap === 'number' ? `${columnGap}px` : spacing[columnGap];
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            column-gap: ${columnGapValue};
          }
        `;
      }
      return css`
        column-gap: ${typeof columnGap === 'number'
          ? `${columnGap}px`
          : spacing[columnGap]};
      `;
    });
  }
  return css`
    column-gap: ${typeof $columnGap === 'number'
      ? `${$columnGap}px`
      : spacing[$columnGap]};
  `;
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-flow: ${({ $autoFlow }) => $autoFlow};
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  justify-content: ${({ $center }) => ($center ? 'center' : 'initial')};
  margin: ${({ $margin }) => $margin};
  max-width: ${({ $maxWidth }) => $maxWidth};

  ${getRowGap};
  ${getColumns};
  ${getColumnGap};
`;
