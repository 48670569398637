import styled, { css, keyframes } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); opacity: 1; }
  to   { transform: translate(-50%, -50%) rotate(360deg); opacity: 1; }
`;

const appear = keyframes`
  0% { transform: translate(-50%, 20%) scale(0.3); opacity: 0; }
  85%   { transform: translate(-50%, -60%) scale(1); opacity: 1 }
  100%   { transform: translate(-50%, -50%) scale(1); opacity: 1 }
`;

export const Natures = {
  default: 'default',
  gray: 'gray',
} as const;

export type ButtonNature = keyof typeof Natures;

interface StyledButtonProps {
  $stretch?: boolean;
  $noWrap?: boolean;
  $center?: boolean;
  $lowerCase?: boolean;
  $iconUrl?: string;
  $nature?: ButtonNature;
}

const getNatureStyle = ({ $nature }: StyledButtonProps) => {
  if ($nature === 'gray') {
    return css`
      padding-left: ${spacing.regular};
      padding-right: ${spacing.regular};
      color: ${colors.solidBlack};
      background-color: ${colors.gray5};

      &:hover {
        background-color: ${colors.gray4};
        border: 1px solid ${colors.gray3};
        transition: 0.2s;
      }
    `;
  }

  return css`
    color: ${colors.gray5};
    background-color: ${colors.solidBlack};
    border: none;

    &:hover {
      background-color: ${colors.solidBlackHover};
      transition: 0.2s;
    }
  `;
};

export const StyledButton = styled.button<StyledButtonProps>`
  font-family: ${fontFamilies.fontRegular};
  padding: 0;
  padding-top: 14px;
  padding-bottom: 14px;
  appearance: none;
  display: ${({ $stretch }) => ($stretch ? 'block' : 'inline-block')};
  width: ${({ $stretch }) => ($stretch ? '100%' : 'auto')};
  min-height: 50px;
  border: 1px solid ${colors.gray4};
  border-radius: 1px;
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  text-align: ${({ $stretch }) => ($stretch ? 'center' : 'initial')};
  text-decoration: none;
  white-space: ${({ $noWrap }) => ($noWrap ? 'noWrap' : 'initial')};
  cursor: pointer;
  transition: 0.1s;

  &.Black {
    padding: 14px ${spacing.regular};
    border: 1px solid ${colors.black};
    background-color: ${colors.black};
    color: ${colors.white};

    &:disabled {
      cursor: not-allowed;
      border: 0;
      transition: 0.2s;
      opacity: 0.5;
      background-color: rgb(17 17 17 / 50%);
      color: ${colors.gray5};
    }
  }

  ${getNatureStyle};

  &:disabled {
    cursor: not-allowed;
    border: 0;
    color: ${colors.gray1};
    background-color: ${colors.warning};
    transition: 0.2s;
  }

  &:focus {
    outline: none;
  }

  &.Icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      display: inline-flex;
      width: ${spacing.regular};
      height: ${spacing.regular};
      ${({ $iconUrl }) =>
        $iconUrl
        && css`
          background-image: ${`url(${$iconUrl})`};
        `}
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: ${spacing.xsmall};
      transition: transform 0.1s linear;
    }
  }

  &.NextIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: inline-flex;
      width: ${spacing.small};
      height: ${spacing.small};
      ${({ $iconUrl }) =>
        $iconUrl
        && css`
          background-image: ${`url(${$iconUrl})`};
        `}
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: ${spacing.xsmall};
      transition: transform 0.1s linear;
    }
  }

  &.AddingInCart {
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    color: ${colors.solidBlack};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      background-image: url('/assets/gfx/loading-circle-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      animation: ${rotate} 1s linear;
      animation-iteration-count: 1;
      transform-origin: center;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      background-image: url('/assets/gfx/check_button_white.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-50%, 40%) scale(0.6);
      opacity: 0;
      animation: ${appear} 0.4s ease-out;
      animation-iteration-count: 1;
      animation-delay: 0.9s;
      animation-fill-mode: forwards;
      transform-origin: center;
    }

    &.White {
      color: ${colors.black};

      &::before {
        background-image: url('/assets/gfx/loading-circle.svg');
      }

      &::after {
        background-image: url('/assets/gfx/check_button.svg');
      }
    }

    &.HideButtonText {
      color: rgb(0 0 0 / 0%);
    }

    &:hover {
      background-color: ${colors.black};
    }
  }
`;
