export const colors = {
  solidOrange: '#FF9012',
  solidOrangeHover: '#F68607',
  ok: '#52E157',
  warning: '#FD2020',
  active: '#4779F6',
  s1: '#FF687A',
  s1p: '#3988FF',
  s2p: '#00BCE5',
  s3: '#813BF6',
  o1: '#00CE9D',
  o2: '#D13BF6',
  gray6: '#F5F5F5',
  gray5: '#F7F7F7',
  gray4: '#EAEAEA',
  gray3: '#DEDEDE',
  gray2: '#B3B3B3',
  gray1: '#8D8D8D',
  solidBlack: '#111111',
  solidBlackHover: '#393939',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  // This are not from the style guide and should be replaced
  black: 'rgba(0, 0, 0, 1)',
  white: 'rgba(255, 255, 255, 1)',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  border: '#E5E5E5',
  // legacy
  hellBlue: '#0000FF',
  hellBlueHover: '#5F5FFF',
  blackHover: '#2D2D2D',
  grayBtnHover: '#E9E8E8',
} as const;

export type Colors = keyof typeof colors;
