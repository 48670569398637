import { useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import Slider from 'react-slick';

import { breakpoints } from '@hultafors/shared/constants';
import { ParttrapImage } from '@hultafors/shared/types';

import { colors, createProductImageSrc } from '@hultafors/solidgear/helpers';

import { ProductImageDialog } from '../ProductImageDialog/ProductImageDialog';
import { TextButton } from '../text-button/text-button';

import { NoImage, ProductSliderStyled } from './product-slider.styled';

interface ProductSliderProps {
  images?: ParttrapImage[];
  productName?: string;
  closeLabel?: string;
}

export const ProductSlider: React.FC<ProductSliderProps> = ({
  images = [],
  closeLabel,
  productName,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [openDialog, setOpenDialog] = useState(false);
  const slider = useRef<any>(null);
  useEffect(() => {
    setCurrentIndex(0);
  }, []);
  const changedIndex = (index: number) => {
    setCurrentIndex(index);
  };
  const SETTINGS = {
    arrows: true,
    dots: false,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    lazyLoad: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow className="Next" />,
    prevArrow: <Arrow className="Prev" />,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          arrows: false,
        },
      },
    ],
    afterChange: changedIndex,
  };
  const canRenderImage = () => {
    return Array.isArray(images) && images.length > 0;
  };
  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  const goToSlider = (i: number) => {
    changedIndex(i);
    slider.current?.slickGoTo(i);
  };
  const bgcolor = formatColor(colors.gray5);
  const showDialog = () => {
    document.body.style.overflowY = 'hidden';
    setOpenDialog(true);
  };
  const closeDialog = () => {
    document.body.style.overflowY = 'initial';
    setOpenDialog(false);
  };

  return (
    <ProductSliderStyled>
      {openDialog && (
        <ProductImageDialog
          images={images}
          onClick={closeDialog}
          closeLabel={closeLabel}
          productName={productName}
        />
      )}
      {canRenderImage()
        ? (
          <div className="SliderAndContentWrapper">
            <div className="ThumbNailWrapper">
              {images.length > 1
              && images.map((image, index) => (
                <span
                  key={`productItem${index}`}
                  className={currentIndex === index ? 'Active' : ''}
                >
                  <Image
                    src={createProductImageSrc(image.url)}
                    onClick={() => goToSlider(index)}
                    alt={image.description || `${productName} image`}
                    fill
                    style={{
                      objectFit: 'contain',
                      backgroundColor: colors.gray5,
                    }}
                    sizes="100px"
                  />
                </span>
              ))}
            </div>
            <div className="SliderAndCounterWrapper">
              <TextButton onClick={showDialog}>
                <Image
                  src="/assets/gfx/plus_16.svg"
                  width={16}
                  height={16}
                  alt="zoom icon"
                />
              </TextButton>
              <Slider {...SETTINGS} className="article" ref={slider}>
                {images.map((image, index) => (
                  <div key={`SliderImage${index}`} className="ImgWrapper">
                    <Image
                      src={createProductImageSrc(image.url)}
                      alt={image.description || `${productName} image`}
                      style={{
                        objectFit: 'contain',
                        backgroundColor: colors.gray5,
                      }}
                      width={692}
                      height={692}
                      priority={true}
                      sizes={`(min-width: ${breakpoints.desktopSmall}) 692px, 100vw`}
                    />
                  </div>
                ))}
              </Slider>
              <div className="Counter">
                <span className="ActiveSlide">
                  {currentIndex ? currentIndex + 1 : 1}
                &nbsp;
                </span>
                /
                {' '}
                {images.length}
              </div>
            </div>
          </div>
          )
        : (
          <NoImage>
            <Image
              src="/assets/gfx/noimage.png"
              width={692}
              height={692}
              sizes={`(min-width: ${breakpoints.desktopSmall}) 692px, 100vw`}
              alt={`${productName} image`}
            />
          </NoImage>
          )}
    </ProductSliderStyled>
  );
};

const Arrow: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...rest
}) => {
  return <button {...rest} />;
};
