import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontFamilies, fontSizes } from '@hultafors/solidgear/helpers';

export const SearchResultHeroStyled = styled.div`
  padding-block: 20px 50px;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > * {
    max-width: 500px;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 70px;
  }
`;

export const ResultText = styled.p`
  font-family: ${fontFamilies.fontRegular};
  font-size: ${fontSizes.body};
  line-height: ${fontSizes.body};
  text-align: center;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    top: 140px;
  }
`;

export const ResultTitle = styled.h1`
  font-family: ${fontFamilies.fontHero};
  font-size: ${fontSizes.h1mobile};
  text-align: center;
`;
