import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';

interface StyledGaugeProps {
  $soFar?: number;
  $toGo?: number;
}

export const GaugeStyled = styled.div<StyledGaugeProps>`
  width: 100%;
  display: flex;
  padding: ${spacing.regular} ${spacing.small} 0 ${spacing.small};

  div {
    height: 2px;
  }

  .SoFar {
    border-radius: 1px;
    background-color: ${colors.gray2};
    transition: width 0.2s ease-out;
    width: ${({ $soFar = 0 }) => $soFar}%;
  }

  .ToGo {
    border-radius: 0 1px 1px 0;
    background-color: ${colors.gray4};
    transition: width 0.2s ease-out;
    width: ${({ $toGo = 100 }) => $toGo}%;
  }
`;
