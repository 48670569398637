import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const Label = styled.span`
  font-size: ${fontSizes.header3Content};
  line-height: ${lineHeights.header3Content};
`;

export const ContactListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  .Accordion > div {
    border-top: 1px solid ${colors.gray4};

    &:first-of-type {
      border-top: none;
    }
  }

  .CollapseHeader {
    height: 72px;
    cursor: pointer;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
  }

  .CollapseContent {
    padding: 0;

    &.isOpen {
      padding-left: 0;
    }

    p {
      margin-bottom: ${spacing.small};
      line-height: ${lineHeights.body};
    }
  }
`;
