import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

export const ShoeGuideStyled = styled.div`
  text-align: center;
  position: relative;

  .GuideContainer {
    padding: ${spacing.small};
    height: 100%;
  }

  .LoaderContainer {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .SlideInBody {
    box-sizing: border-box;
    overflow: scroll;
  }

  .BackButtonContainer {
    text-align: left;

    button {
      padding: 0;

      :hover {
        color: ${colors.solidBlackHover};
      }
    }
  }

  .ShoeGuideMultiChoiceContainer {
    margin-bottom: ${spacing.large};
  }

  .StartButton {
    padding: ${spacing.small} ${spacing.medium};
    font-size: ${fontSizes.body};
    line-height: ${fontSizes.body};
    width: auto;
  }

  .NextButtonContainer {
    position: absolute;
    padding: ${spacing.small};
    bottom: 0;
    width: 100%;
    padding-top: ${spacing.medium};
  }

  .NextButton {
    width: 100%;
  }

  h1 {
    margin: ${spacing.mobileTop} 0 ${spacing.medium} 0;
    text-align: center;
    font-size: ${fontSizes.header1Content};
    line-height: ${lineHeights.header1Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  h2 {
    text-align: center;
    margin: ${spacing.medium} 0 ${spacing.regular} 0;
    font-size: ${fontSizes.header2Content};
    line-height: ${lineHeights.header2Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  h3 {
    text-align: center;
    margin: ${spacing.regular} 0 ${spacing.small} 0;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  .ContentWrapper {
    padding: 20px 20px 104px;
  }

  .Result .ContentWrapper {
    padding: 20px;
  }

  /* From GuideStep */

  input {
    position: absolute;
    opacity: 0;
  }

  .GuideStepHeadingContainer {
    padding: 0 ${spacing.small};
    margin: 0 -${spacing.small};
  }

  .SubAnswersContainer {
    border-top: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};
    padding: 0 ${spacing.small};
    margin-bottom: ${spacing.regular};
  }

  .AnswersContainerMultiChoice {
    padding-top: ${spacing.small};
  }

  .AnswersContainer {
    border-top: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};
    padding: ${spacing.regular} ${spacing.small};
  }

  .CheckboxContainer {
    padding: ${spacing.regular} ${spacing.small};
    border-top: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};

    &:last-of-type {
      border-bottom: 1px solid ${colors.gray4};
    }
  }

  .CheckboxLabel {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;

      /* top: 8px; */
      width: 16px;
      height: 16px;
      background-color: ${colors.gray5};
      border: 1px solid ${colors.gray3};
      border-radius: 2px;
    }

    &:hover {
      color: ${colors.solidBlackHover};
      cursor: pointer;

      &::before {
        background-color: ${colors.gray4};
      }
    }

    span {
      padding-left: 32px;
      padding-right: ${spacing.small};
      font-size: ${fontSizes.small};
      line-height: ${lineHeights.s};
      height: auto;
      border-top: none;
    }

    .ImageWrapper {
      position: absolute;
      top: 6px;
      display: inline-flex;
      width: 16px;
      height: 16px;
      overflow: hidden;

      &.Color {
        border-radius: 50%;
        border: 1px solid ${colors.gray4};
      }

      img {
        width: 100%;
      }
    }
  }

  input:checked + .CheckboxLabel::before {
    background-color: ${colors.solidBlack};
    border-color: ${colors.solidBlack};
    background-image: url('/assets/gfx/check-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  }

  .BottomSection {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    background: ${colors.white};
    box-shadow: 0 0 3px 0 ${colors.boxshadowTransparent};
    z-index: 2;

    button {
      height: 48px;
    }
  }

  .ResultContainer {
    h4 {
      margin: 0 0 8px;
      font-size: 16px;
      font-weight: ${fontWeights.fontMedium};
    }

    ul {
      margin-top: ${spacing.xsmall};
    }

    /* li {
      list-style: none;
      border-bottom: 1px solid ${colors.gray3};
      padding: 16px 0;
      font-size: 14px;
    } */

    .ChoiceContainer {
      padding-bottom: ${spacing.small};
    }

    .ChoicesContainer {
      padding: 0 ${spacing.small};
      margin: 0 -${spacing.small};
      margin-bottom: ${spacing.xsmall};
    }

    .ChoicesAndHeadingContainer {
      margin: 0 -${spacing.small};
      padding: ${spacing.small};
      padding-bottom: 0;
      border-top: 1px solid ${colors.gray4};

      h2 {
        margin-top: 0;
      }
    }

    .RestartButton {
      padding: 0;
      margin: auto;
      margin-top: ${spacing.small};
      display: inline-flex;

      :hover {
        color: ${colors.solidBlackHover};
      }
    }

    .RestartButtonContainer {
      text-align: left;
    }

    p {
      font-size: ${fontSizes.bodyS};
      line-height: ${lineHeights.bodyS};
      text-align: center;
      margin: 0;
    }

    h5 {
      font-size: ${fontSizes.bodyS};
      line-height: ${lineHeights.bodyS};
      margin-bottom: ${spacing.xsmall};
    }

    .ProductInfo {
      text-align: left;
    }

    .BestRecommendationsContainer,
    .OtherRecommendationsContainer {
      border-top: 1px solid ${colors.gray4};
      margin: 0 -${spacing.small};
      padding: 0 ${spacing.small};
    }
  }

  .DoneContainer {
    margin-top: ${spacing.regular};
    margin-bottom: ${spacing.regular};

    h1 {
      margin-top: ${spacing.small};
      margin-bottom: ${spacing.xsmall};
    }
  }

  .LoaderWrapper {
    padding-top: 40px;
  }
`;
