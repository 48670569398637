import { useId } from 'react';

import dynamic from 'next/dynamic';

import { useGlobal } from '@hultafors/solidgear/hooks';
import {
  ContentAreaField,
  isBulletList,
  isCampaignTextBlock,
  isContentBulletList,
  isContentQuote,
  isDotDigitalForm,
  isDoubleTextBlock,
  isDualImage,
  isDualProductBlock,
  isFactBox,
  isGenericPlugBlock,
  isHeroBlock,
  isImageBlock,
  isImageSlider,
  isLinkBoxBlock,
  isOfficesBlock,
  isProductPlugList,
  isRelatedProductsBlock,
  isSafetyClassTableBlock,
  isSafetyStandardTableBlock,
  isShoeGuideCta,
  isSimpleTitleAndTextBlock,
  isSliderBlock,
  isSplitHeroBlock,
  isTableBlock,
  isTextBlock,
  isVideoBlock,
  isVisibleSeoBlock,
} from '@hultafors/solidgear/types';

import { ContentHeroProps } from '../content-hero/content-hero';

import { ContentAreaStyled } from './content-area.styled';

const BulletListBlock = dynamic(() =>
  import('../BulletListBlock/BulletListBlock').then(
    (mod) => mod.BulletListBlock,
  ),
);
const ContentBulletListBlock = dynamic(() =>
  import('../content-bullet-list/content-bullet-list').then(
    (mod) => mod.ContentBulletList,
  ),
);
const OfficesBlock = dynamic(() =>
  import('../contact-stores/contact-stores').then((mod) => mod.ContactStores),
);
const ContentQuoteBlock = dynamic(() =>
  import('../ContentQuoteBlock/ContentQuoteBlock').then(
    (mod) => mod.ContentQuoteBlock,
  ),
);
const SplitHeroBlock = dynamic(() =>
  import('../content-hero/content-hero').then((mod) => mod.ContentHero),
);
const DotDigitalFormBlock = dynamic(() =>
  import('../DotDigitalFormBlock/DotDigitalFormBlock').then(
    (mod) => mod.DotDigitalFormBlock,
  ),
);
const TwoColumnText = dynamic(() =>
  import('../TwoColumnText/TwoColumnText').then((mod) => mod.TwoColumnText),
);
const DualImage = dynamic(() =>
  import('../DualImage/DualImage').then((mod) => mod.DualImage),
);
const DualProductBlock = dynamic(() =>
  import('../DualProductBlock/DualProductBlock').then(
    (mod) => mod.DualProductBlock,
  ),
);
const FactBoxBlock = dynamic(() =>
  import('../FactBoxBlock/FactBoxBlock').then((mod) => mod.FactBoxBlock),
);
const GenericPlugBlock = dynamic(() =>
  import('../GenericPlugBlock/GenericPlugBlock').then(
    (mod) => mod.GenericPlugBlock,
  ),
);
const HeroBlock = dynamic(() =>
  import('../HeroBlock/HeroBlock').then((mod) => mod.HeroBlock),
);
const ImageBlock = dynamic(() =>
  import('../image-block/image-block').then((mod) => mod.ImageBlock),
);
const ImageSliderBlock = dynamic(() =>
  import('../image-slider-block/image-slider-block').then(
    (mod) => mod.ImageSliderBlock,
  ),
);
const LinkBoxBlock = dynamic(() =>
  import('../LinkBoxBlock/LinkBoxBlock').then((mod) => mod.LinkBoxBlock),
);
const ProductPlugsBlock = dynamic(() =>
  import('../ProductPlugsBlock/ProductPlugsBlock').then(
    (mod) => mod.ProductPlugsBlock,
  ),
);
const RelatedProductsBlock = dynamic(() =>
  import('../related-products-block/related-products-block').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const SafetyClassTableBlock = dynamic(() =>
  import('../safety-class-table-block/safety-class-table-block').then(
    (mod) => mod.SafetyClassTableBlock,
  ),
);
const SafetyStandardTableBlock = dynamic(() =>
  import('../safety-standard-table-block/safety-standard-table-block').then(
    (mod) => mod.SafetyStandardTableBlock,
  ),
);
const ShoeGuideCtaBlock = dynamic(() =>
  import('../ShoeGuideCtaBlock/ShoeGuideCtaBlock').then(
    (mod) => mod.ShoeGuideCtaBlock,
  ),
);
const SliderBlock = dynamic(() =>
  import('../slider-block/slider-block').then((mod) => mod.SliderBlock),
);
const SimpleTitleAndTextBlock = dynamic(() =>
  import('../simple-title-and-text-block/simple-title-and-text-block').then(
    (mod) => mod.SimpleTitleAndTextBlock,
  ),
);
const TableBlock = dynamic(() =>
  import('../TableBlock/TableBlock').then((mod) => mod.TableBlock),
);
const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../VideoBlock/VideoBlock').then((mod) => mod.VideoBlock),
);
const VisibleSeoBlock = dynamic(() =>
  import('../VisibleSeoBlock/VisibleSeoBlock').then(
    (mod) => mod.VisibleSeoBlock,
  ),
);

export { BlockWrapper } from './content-area.styled';

interface ContentAreaProps {
  content: ContentAreaField[];
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content }) => {
  const uid = useId();
  const { isEnabled } = useGlobal();

  const contentMapper = (item: any, index: number) => {
    const key = `ContentArea-${uid}-${item.id}`;
    const priority = index === 0;
    if (isBulletList(item)) {
      return <BulletListBlock data={item} key={key} />;
    }
    if (isCampaignTextBlock(item)) {
      return <BulletListBlock data={item} key={key} />;
    }
    if (isContentBulletList(item)) {
      return <ContentBulletListBlock {...item} key={key} />;
    }
    if (isContentQuote(item)) {
      return <ContentQuoteBlock {...item} key={key} />;
    }
    if (isDotDigitalForm(item)) {
      return <DotDigitalFormBlock {...item} key={key} />;
    }
    if (isDualImage(item)) {
      return <DualImage {...item} key={key} />;
    }
    if (isDualProductBlock(item)) {
      return <DualProductBlock {...item} key={key} />;
    }
    if (isFactBox(item)) {
      return <FactBoxBlock {...item} key={key} />;
    }
    if (isGenericPlugBlock(item) && isEnabled('genericPlugBlock')) {
      return <GenericPlugBlock {...item} key={key} />;
    }
    if (isHeroBlock(item)) {
      return <HeroBlock {...item} key={key} priority={priority} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlock {...item} key={key} />;
    }
    if (isImageSlider(item)) {
      return <ImageSliderBlock {...item} key={key} />;
    }
    if (isLinkBoxBlock(item)) {
      return <LinkBoxBlock {...item} key={key} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock {...item} key={key} />;
    }
    if (isProductPlugList(item)) {
      return <ProductPlugsBlock {...item} key={key} />;
    }
    if (isRelatedProductsBlock(item)) {
      return <RelatedProductsBlock {...item} key={key} />;
    }
    if (isSafetyClassTableBlock(item)) {
      return <SafetyClassTableBlock {...item} key={key} />;
    }
    if (isSafetyStandardTableBlock(item)) {
      return <SafetyStandardTableBlock {...item} key={key} />;
    }
    if (isShoeGuideCta(item) && isEnabled('shoeGuideBlock')) {
      return <ShoeGuideCtaBlock {...item} key={key} />;
    }
    if (isSliderBlock(item)) {
      return <SliderBlock {...item} key={key} />;
    }
    if (isSimpleTitleAndTextBlock(item)) {
      return (
        <SimpleTitleAndTextBlock {...item} key={key} priority={index === 0} />
      );
    }
    if (isSplitHeroBlock(item)) {
      return (
        <SplitHeroBlock
          {...(item as ContentHeroProps)}
          type="contentView"
          splitHero={item?.alignHorizontally}
          key={key}
        />
      );
    }
    if (isTableBlock(item)) {
      return <TableBlock {...item} key={key} priority={priority} />;
    }
    if (isTextBlock(item)) {
      return <TextBlock {...item} key={key} />;
    }
    if (isDoubleTextBlock(item)) {
      return <TwoColumnText {...item} key={key} />;
    }
    if (isVideoBlock(item)) {
      return <VideoBlock {...item} key={key} />;
    }
    if (isVisibleSeoBlock(item) && isEnabled('visibleSeoBlock')) {
      return <VisibleSeoBlock {...item} key={key} />;
    }
    return null;
  };

  return <ContentAreaStyled>{content.map(contentMapper)}</ContentAreaStyled>;
};
