import styled from 'styled-components';

import { colors, lineHeights, spacing } from '@hultafors/solidgear/helpers';

import { TextButton } from '../text-button/text-button';

export const FilterTextButton = styled(TextButton)`
  background-color: ${colors.solidBlack};
  color: ${colors.white};
  height: 48px;
  padding: 12px 24px;
  line-height: ${lineHeights.body};

  /* Below sets the left svg background-image size. */
  ::before {
    height: 24px;
    width: 24px;
  }

  margin-bottom: ${spacing.regular};
`;
